<script setup lang="ts">
defineOptions({
  name: 'MopJobFilters',
});

defineProps({
  count: {
    type: Number,
    default: 0,
  },
});

const route = useRoute();
const { $mopI18n, $urls } = useNuxtApp();
const { handleBuildUrlParams, selectedFiltersRef, filtersRef, isFilterApplied } = useMopJob();
const clearFiltersUrlRef = computed(() => {
  if (route.query?.search) {
    return $mopI18n.localePath(`${$urls.CAREER_PAGE}?search=${route.query.search}`);
  }
  return $mopI18n.localePath(`${$urls.CAREER_PAGE}`);
});

const visibleFilterGroupsRef = computed(() => {
  return Object.keys(filtersRef.value).filter((groupName) => {
    return groupName === 'region' ? isFilterApplied('country') : true;
  });
});
</script>

<template>
  <div class="job-filters">
    <div class="job-filters__headline-wrapper">
      <div class="job-filters__headline">
        {{ $mopI18n.t('locale.jobs.filters') }}
      </div>
      <div class="job-filters__count">
        {{ $mopI18n.t('locale.jobs.total_positions', [count]) }}
      </div>
    </div>
    <div v-if="selectedFiltersRef.length > 0" class="job-filters__selected-filters">
      <NuxtLink
        v-for="(filter, i) in selectedFiltersRef"
        :key="`selected-${filter.id}-${i}`"
        class="job-filters__selected-filter"
        no-prefetch
        :to="handleBuildUrlParams({ filter: { [filter.group]: filter.id }, page: 1 })"
      >
        {{ filter.name }} <i class="job-filters__selected-filter-icon icon icon--x" />
      </NuxtLink>

      <div class="job-filters__clear-filters-wrap">
        <NuxtLink class="job-filters__clear-filters" :to="clearFiltersUrlRef" no-prefetch>
          {{ $mopI18n.t('locale.jobs.clear_all_filters') }}
        </NuxtLink>
      </div>
    </div>
    <MopJobFilter
      v-for="filter in visibleFilterGroupsRef"
      :key="filter"
      :class="`job-filters__${filter}`"
      :headline="filter"
      :filter-type="filter"
      :filters="filtersRef[filter]"
      :show-empty="filter !== 'region'"
    />
  </div>
</template>

<style lang="scss" scoped>
.job-filters__headline-wrapper {
  @include text-style(strong);

  margin-bottom: $space15;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.job-filters__count {
  margin-left: $space20;
  opacity: $disabled-opacity;
}

.job-filters__selected-filters {
  margin-bottom: $space20;
}

.job-filters__clear-filters-wrap {
  margin-top: $space10;
}

.job-filters__selected-filter {
  @include link-not-underlined(2px);

  margin-right: $space10;
  display: inline-flex;
}

.job-filters__clear-filters {
  @include link-underlined(2px);
}

.job-filters__selected-filter-icon {
  margin-top: -3px;
  margin-left: 2px;
}
</style>
